$my-yellow: #ebab1f;

$my-black: #3b3b3b;
$my-black-faded: #3b3b3b88;

body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;

    //font: 400 16px/1.5 helvetica neue, Helvetica, Arial, sans-serif;
    color: #111;
    background-color: #fdfdfd;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    font-size: 1.4em;
}


.main-content {
    margin: 3rem;
}


.nav-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.nav-li {
    float: left;
    padding: 0.5rem;
}

a.nav-a, a.nav-a:visited {
    display: block;
    color: $my-yellow;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: semi-bold;
    text-shadow: 0px 1px 1px $my-black-faded;
}

.about-para-div {
    margin: 3rem;
}

.logo-hero-div {
    width: 30rem;
    margin-bottom: 2rem;
}

.content-wrapper > a {
    font-size: large;
    font-weight: bold;
}

header {
     .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4rem;
        border-bottom: $my-black-faded 2px solid;
        padding-bottom: 1rem;
    }
}

.content-wrapper {
    margin: 4rem 0rem 4rem 0rem;
}
.content-wrapper {
    display: block;
    width: 100%;
    .hero-wrapper {
        display: block;
        width: 100%;
        margin-bottom: 3rem;
        .hero {
            display: block;
            width: 100%;
            max-height: 300px;
            overflow: hidden;

            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}


.embed-wrapper{
    width: 100%;
    margin: 2rem;
    .embed-title {
        width: 100%;
    }
    .embed {
        max-width: 25%;
    }
}

a, a:visited {
    color: $my-yellow;
    text-decoration: none;
}

.top-margin-blog-post {
    margin-top: 2rem;
}

ul.blog-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        margin: 2rem;
    }
}

.post-date {
    font-size: 0.9rem;
    color: #777;
    font-style: italic;
}

.footer-wrapper {
    margin-top: 7rem;

    .footer {
        margin: 4rem;
        border-top: $my-black-faded 2px solid;
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footer-left-item {
                margin: 0rem 1rem 0rem 1rem;
            }
        }
        .footer-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footer-right-item {
                margin: 0rem 1rem 0rem 1rem;
            }
        }
        i {
            text-shadow: 0px 1.5px 1.5px $my-black-faded;
        }
    }
}

.post-image-wrapper {
    margin: 2rem 0rem 2rem 0rem;
    width: 100%;
    max-width: 100px;

    .post-image {
        width: 100%;
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }
}

// .index-image-container {

//     .index-content-wrapper {
//         display: flex;
//         flex-direction: row;

//         .index-image-wrapper {
//             margin: 2rem 0rem 2rem 0rem;
//             width: 100%;
//             max-width: 100px;

//             .post-image {
//                 order: 0;
//                 width: 100%;

//                 img {
//                     max-width: 200px;
//                     max-height: 200px;
//                 }

//             }

//         }

//         .href, .post-description {
//             // Add your styles for href and post.description here
//         }
//     }
// }


.index-image-wrapper {
    margin: .5rem 0rem .5rem 0rem;
    width: 100%;
    max-width: 100px;
    .post-image {
        width: 100%;
        img {
            max-width: 100px;
            max-height: 100px;
        }
    }
}

.border-2 {
    border: $my-black-faded 2px solid;
}

.content-wrapper {
    ul.task-list {
        margin-left: 4rem;
    }
}

.content-indent {
    margin-left: 4rem;
    margin-right: 4rem;
}

.footer-links {
    // Make the list horizontal instead of vertical
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;

    float: left;
    ul {
        float: left;
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;
        li {
            text-align: center;
            float: left;
            margin: 1rem;
            text-align: center;
        }
    }
}



.outer-container {
    // height: 100%;
    // width: 100%;
    border-radius: 2rem;
    max-width: 540px;
    // background-color: $my-black;
    .contact-container {
        margin: auto;
        //width: 75%;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 20px;
        letter-spacing: 0.08em;
        // background: url("/images/contact-bg.jpg") no-repeat;
        background-size: 100%;
        color: $my-yellow;
        padding: 40px;
        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        label {
            text-shadow: 0px 4px 4px $my-black-faded;
        }
        .form-group {
            margin-top: 1rem;
        }
        input {
            height: 61px;
        }
        textarea {
            height: 213px;
        }
        textarea, input {
            background-color: #ffffff0f;
            width: 408px;
            border: 1px solid #676767;
            max-width: 90%;
            color: #ffffff;
            padding: 0 1em;
        }
        .submit {
            margin-top: 20px;
            width: 408px;
            height: 61px;
            left: 39px;
            top: 973px;
            background: $my-yellow;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            line-height: normal;
            font-size: 20px;
            letter-spacing: 0.08em;
            color: #FFFFFF;
            max-width: 90%;
            border: 2px solid $my-black-faded;
            border-radius: 2rem;
        }
        .buttons {
            margin-top: 50px;
            .button-container {
                a img {
                    margin: 10px;
                }
            }
        }
        .subscribe {
            .open-modal  img {
                width: 200px;
            }
        }
    }
}

.hidden {
    display: none;
}

.footer-copyright {
    font-size: smaller;
    color: $my-black-faded;
    font-style: italic;
    text-align: center;
}

.yt-tags-wrapper {
    margin: 2rem 0rem 2rem 0rem;
    .yt-tag {
        padding: 0.5rem;
        text-shadow: $my-black-faded 0px 1px 1px;
    }
}

.blog-list-items{
    .post-image{
        float: left;
        margin-right: 1rem;
        padding-top: 0.5rem;
        img {
            max-width: 100px;
            max-height: 100px;
            border: 2px solid $my-black-faded;
            box-shadow: 2px 2px 2px $my-black-faded;
            cursor: pointer;
        }
    }
}